import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import NewsSectionBlock from '../components/NewsSectionBlock'
import SEO from '../components/seo'
import Typography from '../components/Common/Typography'
import List from '../components/Common/List'

import * as s from '../pages/news.module.scss'

const SignaxRockedCtfKsa: React.FC = () => {
  return (
    <>
      <SEO
        title="SIGNAX rocked Construction Technology Festival KSA"
        description="2-days Construction Technology Festival KSA took place in Riyadh, December, 14-15."
      />
      <NewsSectionBlock date="23.12.2022">
        <Typography variant="h1" color="blue">
          SIGNAX rocked Construction Technology Festival KSA
        </Typography>
        <Typography variant="body1">
          2-days Construction Technology Festival KSA took place in Riyadh,
          December, 14-15.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            width={580}
            src="../assets/images/news/2022-12-23-signax-rocked-ctf-ksa/image-1.jpeg"
            alt="SIGNAX rocked Construction Technology Festival KSA"
            title="SIGNAX rocked Construction Technology Festival KSA"
            placeholder="blurred"
          />
        </div>
        <Typography variant="body1">
          Forum attracted 500+ delegates, visitors and exhibitors. Abdullah
          Mohammed Albudair, Deputy Minister of Municipal Rural Affairs &
          Housing opened the event. He announced that KSA keeps a course on
          innovations in construction and gave the prizes to SCA’s Contracting
          Excellence Awards winners. The first day consisted of Keynote session
          and 4 tracks including:
        </Typography>
        <List className="pl-4">
          <li>Design Automation, Innovation, Dfma and Offsite;</li>
          <li>BIM, Virtual Construction & GIS;</li>
          <li>Sustainability, Materials, Digital Twins.</li>
        </List>
        <Typography variant="body1">
          Second day was devoted to Workshops and SCA’s Saudi Project Networking
          Day.
        </Typography>
        <Typography variant="body1">
          SIGNAX was one of the most active participants having the stand and
          presentations during both days of the event. Petr Manin, SIGNAX
          Regional Director, opened BIM track with «BIM for owners – remote
          supervision of site for enhanced quality, cost, safety and
          construction dynamics control». Roman Ruksha, Account Director,
          provided 1,5 hours «BIM for Developers and Contractors Onsite»
          workshop. SIGNAX stand was extremely popular among the visitors and
          Abdullah Albudair was one of them.
        </Typography>
        <Typography variant="body1">
          Distinguished guests showed interest in the company's developments and
          expressed hope for further cooperation within the framework of KSA
          most important projects. SIGNAX is new to KSA market, at the same
          time, Saudi owners and contractors liked the idea of using digital on
          site and strive to implement these technologies to their processes.
          SIGNAX is fully ready to help them along the way providing
          best-in-class solutions and excellent service.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            className="mb-8"
            src="../assets/images/news/2022-12-23-signax-rocked-ctf-ksa/image-2.jpeg"
            alt="SIGNAX rocked Construction Technology Festival KSA 2"
            title="SIGNAX rocked Construction Technology Festival KSA 2"
            placeholder="blurred"
          />
          <StaticImage
            className="mb-8"
            src="../assets/images/news/2022-12-23-signax-rocked-ctf-ksa/image-3.png"
            alt="SIGNAX rocked Construction Technology Festival KSA 3"
            title="SIGNAX rocked Construction Technology Festival KSA 3"
            placeholder="blurred"
          />
          <iframe
            width="800"
            height="437"
            src="https://kuula.co/share/collection/79xM5?logo=1&info=0&fs=1&vr=0&zoom=1&thumbs=0&inst=0"
            title="Virtual Tours"
            frameBorder="0"
            allow="xr-spatial-tracking; gyroscope; accelerometer"
            allowFullScreen
            scrolling="no"
          />
          <StaticImage
            className="mt-8"
            src="../assets/images/news/2022-12-23-signax-rocked-ctf-ksa/image-4.jpeg"
            alt="SIGNAX rocked Construction Technology Festival KSA 4"
            title="SIGNAX rocked Construction Technology Festival KSA 4"
            placeholder="blurred"
          />
        </div>
      </NewsSectionBlock>
    </>
  )
}

export default SignaxRockedCtfKsa
